import React, { FC } from 'react';
import ContentfulPageModules from '../components/contentful-page/contentful-page-modules';
import MetaModule from '../components/core/meta-module';
import EditorialIndexCard from '../components/editorial/editorial-index-card';
import { Article, ContentfulPage } from 'superclient';

const TemplateContentfulPage: FC<{
  pageContext: {
    page: ContentfulPage;
    dropboxArticles: Article[];
  };
}> = ({ pageContext: { page, dropboxArticles } }) => {
  return (
    <div className="template__page">
      <MetaModule title={page.title} path={`/${page.slug}`} />
      <ContentfulPageModules modules={page.modules} />
      {page.slug === 'dropbox' && (
        <div className="container--xl ma p15">
          <h6 className="caps pb0">Sponsored by Dropbox</h6>
          {dropboxArticles.length && (
            <div className="f jcb ais fw">
              {dropboxArticles.map((article) => (
                <EditorialIndexCard key={article.id} article={article} />
              ))}
              <div className="article__card" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateContentfulPage;
