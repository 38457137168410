import React from 'react';

export default function DropboxLogo() {
  return (
    <svg width="58px" height="49px" viewBox="0 0 58 49">
      <g
        id="Subbrand-Logos"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(-3.070312, -7.437500)"
          fill="currentColor"
        >
          <g id="Glyph/Dropbox">
            <g id="Group" transform="translate(3.116883, 7.480519)">
              <path
                d="M43.4369036,18.3500644 L57.7884675,27.4931948 L43.3358961,36.7006753 L28.8941299,27.5002109 L14.4525714,36.7006753 L0,27.4931948 L14.3513568,18.3501963 L0,9.20727273 L14.4527792,0 L28.8940263,9.20032289 L43.3361039,0 L57.7882597,9.20727273 L43.4369036,18.3500644 Z M43.2346801,18.3501963 L28.894026,9.21422256 L14.5535789,18.3500644 L28.8941299,27.4861787 L43.2346801,18.3501963 Z M14.5454545,39.7518961 L28.998026,30.5454545 L43.4508052,39.7518961 L28.998026,48.9593766 L14.5454545,39.7518961 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
