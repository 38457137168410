import React, { FC, useEffect, useRef, useState } from 'react';
import YouTubePlayer from 'youtube-player';
import classNames from 'classnames';

import DropboxLogo from '../svgs/dropboxLogo';

const ContentfulPageHero: FC<{ item: any }> = ({ item }) => {
  const playerWrapperRef = useRef<any>(null);
  const youtubeRef = useRef<any>(null);

  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const player = playerWrapperRef.current.querySelector('.js-video');
    if (player) {
      youtubeRef.current = YouTubePlayer(player, {
        videoId: item.fields.youtubeUrlId
      });
    }
  }, []);

  const playVideo = () => {
    if (item.fields.youtubeUrlId) {
      setShowVideo(true);
      youtubeRef.current.playVideo();
    }
  };

  return (
    <div
      key={item.sys.id}
      className={classNames('rel hero', {
        video: item.fields.youtubeUrlId
      })}
      ref={playerWrapperRef}
      onClick={() => playVideo()}
    >
      {item.fields.title && (
        <div className="abs z1 x y top left f ac jcc aic">
          <div>
            {/dropbox/.test(item.fields.title.toLowerCase()) ? (
              <h1 className="cw p1">
                Supercluster +{' '}
                <span className="inline-block">
                  <DropboxLogo /> Dropbox
                </span>
              </h1>
            ) : (
              <h1 className="cw p1">{item.fields.title}</h1>
            )}
            {item.fields.youtubeUrlId && <div className="hero__play" />}
          </div>
        </div>
      )}
      {item.fields.youtubeUrlId && (
        <div
          className={`hero__video abs top left x y ${showVideo ? 'visible' : ''}`}
        >
          <div className="js-video" />
        </div>
      )}
      <img
        src={`${item.fields.coverImage.fields.file.url}?fm=jpg&q=70&fl=progressive`}
        alt={item.fields.title}
      />
    </div>
  );
};

export default React.memo(ContentfulPageHero);
